<template>
  <ProViewProposals :title="title" :tenderers="tenderers" />
</template>

<script>
import ProViewProposals from "@/components/PROSmart/ProViewProposals";

export default {
  components: {
    ProViewProposals,
  },
  name: "ViewTechnicalProposals.vue",
  data() {
    return {
      title: "Tenderers",
      tenderers: [],
    };
  },
  async mounted() {
    const res = await this.$proSmart.tenderView.getProposals(
      this,
      this.$route.params.id,
      "TechnicalOpening"
    );

    this.tenderers = res.reduce(
      (array, { ref, name, technicalFolder: folders }) => {
        return [
          ...array,
          {
            ref,
            name,
            folders,
            // actions: [{ processId: processId, viewForm: true }],
          },
        ];
      },
      []
    );
  },
};
</script>
